<template>
  <b-card>
    <h3>Tutorials</h3>
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue';

export default {
  components: {
    BCard,
  },
  data() {
    return {
      modalShow: false,
      modalShowEdit: false,
      searchTerm: '',
    };
  },
};
</script>
